import React, { useEffect } from "react";
import { DEFAULT_LOT_IMAGE, LOT_DETAILS_URL } from "../settings";
import ToggleLotIsFavorite from "../Common/ToggleLotIsFavorite/ToggleLotIsFavorite";
import AccountInfo from "../Profile/AccountInfo";
import { CardLotModel } from "./types";
import { ApplicationAcceptance } from "../TradeApplication/components/ApplicationAcceptance";
import { IDeliveryData } from "../Common/types";
import deliveryLot from "../LotsEditor/DeliveryLot";

/**
 * @interface IPropsType - интерфейс, описывающий свойства, передаваемые в компонент CardLot.
 * @property lot - данные конкретного лота, для отображения их в карточке.
 * @property onIsFavoriteToggle - ? не используется.
 * @property showApp - ? не используется.
 * @property deliveryLotDetails - данные доставки лота (опционально). Передаётся из DeliveryDetailsList для отображения данных по отправкам для продавца.
 * @property isAcceptance - флаг, указывающий, включает ли лот в себя приём заявок.
 * @property onRegisterOrder -  событие (функция), на клик по кнопке создать заказ, если это данные доставки (опционально). Передаётся из DeliveryDetailsList.
 * @property defineCurrentLot - функция, которая устанавливает состояние текущего лота для компонента DeliveryDetailsList. Обратный вызов. Опционально.
 * @property changeSendingStatus - функция, меняющая статус доставки на основании статуса сдек, для отображения его в другой вкладке, 
 * соответствующей статусу доставки. Передаётся из DeliveryDetailsList. Опционально.
 * @property recipientName - имя получателя, передаваемое из DeliveryDetailsList для отображения. Опционально.
 */
interface IPropsType {
    lot: CardLotModel;
    onIsFavoriteToggle?: Function;
    showApp?: boolean;
    deliveryLotDetails?: IDeliveryData;
    isAcceptance: boolean;
    onRegisterOrder?: (lotId: number) => void; // Передаем lotId,
    defineCurrentLot?: (lotId: number) => void; //обратный вызов для установки текущего лота
    changeSendingStatus?: (lotId: number, cdekStatus: string) => void;
    recipientName?: string
}

/**
 * @function CardLot - функциональный компонент, отвечающий за формирование карточки лота. В случаях, если опциональные свойства передаются из 
 * DeliveryDetailsLot, отображает также данные доставки. В остальных случаях используется как простая карточка лота, использующаяся в Аукционы и Моя активность.
 */
function CardLot({ lot, showApp, deliveryLotDetails, isAcceptance, onRegisterOrder, defineCurrentLot, changeSendingStatus, recipientName }: IPropsType) {
    let url = lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId;

    /**
     * @effect - логгирование пути изображения для лота при изменении лота
     */
    useEffect(() => {
        console.log(lot.ImagePath);
    }, [lot]);

    /**
     * @effect - установка текущего лота для состояния в DeliveryDetailsList
     */
    useEffect(() => {
        console.log("defineCurrentLot", defineCurrentLot);
        console.log("type of defineCurrentLot", typeof(defineCurrentLot));
        if (defineCurrentLot) {
            defineCurrentLot(lot.LotId);
        }
    }, [lot]);

    console.log(deliveryLot[lot.LotId]);
    return (
        <div className="cardContent">
            <div className="cardImage">
                <a href={url} data-attribute-URL_={url}>
                    {(lot.ImagePath && lot.ImagePath.length > 0) ?
                        <img className="img-100w" key={lot.ImageId} src={lot.FullImagePath} alt={lot.ImageName} />
                        :
                        <img src="../../main-images/defaultLotImage.png" className="img-100w" alt="Картинка по умолчанию" />
                    }
                </a>
                {AccountInfo.Id &&
                    <ToggleLotIsFavorite lotId={lot.LotId} curUserFavorite={lot.IsInFavorites} showText={false} type="lot" />
                }
            </div>
            <div className="cardInfo">
                <a className="cardName" href={url} data-attribute-URL_={url}>
                    {lot.LotName}
                </a>

                <div className="cardDescription">
                    <div className="cartType cardDescriptionItem">
                        <h5>Тип торга:</h5>
                        <span>{lot.AuctionType}</span>
                    </div>
                    <div className="cardTypeSale cardDescriptionItem">
                        <h5>Статус:</h5>
                        <span>{lot.StatusName}</span>
                    </div>
                    <div className="cardNumber cardDescriptionItem">
                        <h5>Номер лота:</h5>
                        <span>{lot.LotId}</span>
                    </div>
                    <div className="cardPrice cardDescriptionItem">
                        <h5>Текущая цена:</h5>
                        <span>{lot.Price.toLocaleString('ru')} руб.</span>
                    </div>

                    {/* Отображение данных о доставке */}
                    {deliveryLotDetails && (
                        <div className="sendingType cardDescriptionItem" style={{ gridColumn: '1 / -1' }}>
                            <h5>Данные о доставке:</h5>
                            <div className="deliveryDetails">
                                <span>Тариф: {deliveryLotDetails.DeliveryDetails?.Tariff}</span>
                                <span>Цена доставки: {deliveryLotDetails.DeliveryDetails?.Price} руб.</span>
                                <span>Адрес отправления: {deliveryLotDetails.DeliveryStartPoint?.Address}, {deliveryLotDetails.DeliveryStartPoint?.City}</span>
                                <span>Адрес назначения: {deliveryLotDetails.DeliveryEndPoint?.Address}, {deliveryLotDetails.DeliveryEndPoint?.City}</span>
                                <span>Имя получателя: {recipientName}</span>
                                <span>Номер заказа: {deliveryLotDetails.DeliveryDetails?.CdekNumber}</span>
                                <span>Последний статус от СДЕК: {deliveryLotDetails.DeliveryDetails?.LastCdekStatus}</span>
                            </div>
                            {/* Передаем lotId в onRegisterOrder */}
                            {(!deliveryLotDetails.DeliveryDetails.OrderUuid || deliveryLotDetails.DeliveryDetails.LastCdekStatus === "Некорректный заказ") && <button onClick={() => onRegisterOrder && onRegisterOrder(lot.LotId)}>
                                Зарегистрировать заказ
                            </button>}
                            {deliveryLotDetails.DeliveryDetails.OrderUuid  && <button onClick={() => changeSendingStatus(lot.LotId, deliveryLotDetails.DeliveryDetails.LastCdekStatus)}>Обновить</button>}
                        </div>
                    )}
                </div>

                {isAcceptance &&
                    <ApplicationAcceptance
                        lotId={lot.LotId}
                    />
                }
                <div className="cardActivity">
                    <div className="views cardActivityItem">
                        <img src="../../../main-images/views.svg" alt="views"></img>
                        <span>152</span>
                    </div>
                    <div className="viewNow cardActivityItem">
                        <img src="../../../main-images/viewNow.svg" alt="view now"></img>
                        <span>10</span>
                    </div>
                    <div className="applications cardActivityItem">
                        <img src="../../../main-images/applications.svg" alt="applications"></img>
                        <span>6 Заявок</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardLot;