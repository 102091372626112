import React, { useState, useEffect } from "react";
import Fetcher from "../Common/Fetch";
import { IDimensions, IDeliveryPoint, IDeliveryDetails } from "../Common/types";
import { SendingStatuses, TradeStates } from "../MyActivity/types";

/**
 * @interface IPropsType - интерфейс, описывающий свойства, передаваемые в компонент CDEKWidget.
 * @property lotId - уникальный идентификатор лота.
 * @property tradeStateId - идентификатор состояния торгов (опционально). Передаётся, когда виджет открывается не через кнопку Купить сейчас, 
 * а в результате победы в аукционе
 * @property priceHistoryLength - длина истории цен (опционально). Передаётся, когда виджет открывается не через кнопку Купить сейчас, 
 * а в результате победы в аукционе
 */
interface IPropsType {
    lotId: number;
    tradeStateId?: number;
    priceHistoryLength?: number;
}

/**
 * @function CDEKWidget - функциональный компонент, отвечающий за отображение и управление виджетом доставки СДЭК.
 * Компонент позволяет выбирать способ доставки, рассчитывать стоимость и сохранять данные доставки. Вызывается после попапа купить сейчас, 
 * а также по кнопке, показывающейся победителю.
 */
function CDEKWidget(props: IPropsType) {
    const [dimensions, setDimensions] = useState<IDimensions | null>(null); // Состояние для габаритов груза
    const [deliveryStartPoint, setDeliveryStartPoint] = useState<IDeliveryPoint | null>(null); // Состояние для начального пункта доставки
    const [deliveryEndPoint, setDeliveryEndPoint] = useState<IDeliveryPoint | null>(null); // Состояние для конечного пункта доставки
    const [deliveryDetails, setDeliveryDetails] = useState<IDeliveryDetails | null>(null); // Состояние для деталей доставки
    const [isDeliveryChosen, setIsDeliveryChosen] = useState(false); // Флаг выбора способа доставки
    const [readyToSave, setReadyToSave] = useState(false); // Флаг готовности к сохранению данных
    const [isSaved, setIsSaved] = useState(false); // Флаг успешного сохранения данных
    const [isWidgetVisible, setIsWidgetVisible] = useState(true); // Флаг видимости виджета

    /**
     * @effect - эффект для загрузки данных лота при монтировании компонента.
     * Загружает данные о доставке, габаритах груза и пунктах доставки. Нужно для рассчёта стоимости
     */
    useEffect(() => {
        const fetchLotData = async () => {
            try {
                const response = await Fetcher(`/CdekApi/GetDeliveryDetails?lotId=${props.lotId}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });

                if (response.ok) {
                    const data = await response.json();
                    const deliveryDetails = JSON.parse(data.UpdatedItem);

                    if (deliveryDetails) {
                        setDimensions({
                            Width: deliveryDetails.Width,
                            Height: deliveryDetails.Height,
                            Length: deliveryDetails.Length,
                            Weight: deliveryDetails.Weight,
                        });

                        setDeliveryStartPoint({
                            CountryCode: deliveryDetails.FromAddress?.CountryCode || "RU",
                            City: deliveryDetails.FromAddress?.City || "",
                            Address: deliveryDetails.FromAddress?.Address || "",
                        });

                        setDeliveryEndPoint({
                            CountryCode: deliveryDetails.ToCountryCode || "RU",
                            City: deliveryDetails.ToCity || "",
                            Address: deliveryDetails.ToAddress || "",
                        });

                        setDeliveryDetails({
                            Tariff: deliveryDetails.Tariff || null,
                            LotId: deliveryDetails.LotId || null,
                            Price: deliveryDetails.Price || null,
                            WinnerId: deliveryDetails.WinnerId || null,
                            Duration: deliveryDetails.DeliveryDuration || null,
                            Type: deliveryDetails.DeliveryType || null,
                            FromAddressId: deliveryDetails.FromAddressId || null,
                            SendingStatus: deliveryDetails.SendingStatus || null,
                            ShipmentCode: deliveryDetails.ShipmentCode || null,
                            LastCdekStatus: deliveryDetails.LastCdekStatus || null,
                            CdekNumber: deliveryDetails.CdekNumber || null,
                            OrderUuid: deliveryDetails.OrderUuid || null,
                            TariffCode: deliveryDetails.TariffCode || null,
                        });

                        console.log("Детали доставки", deliveryDetails);
                        console.log("Данные начального пункта", deliveryStartPoint);
                        console.log("Данные конечного пункта", deliveryEndPoint);
                    }
                }
            } catch (error) {
                console.error("Ошибка загрузки данных лота:", error);
            }
        };

        fetchLotData();
    }, [props.lotId]);

    /**
     * @effect - эффект для инициализации виджета СДЭК после получения данных о габаритах и начальном пункте доставки.
     * Виджет инициализируется с использованием данных о местоположении пользователя.
     */
    useEffect(() => {
        const initializeCDEKWidget = async (userLocation = null) => {
            const CDEKWidget = (window as any).CDEKWidget;

            if (CDEKWidget && dimensions && deliveryStartPoint) {
                const widget = new CDEKWidget({
                    from: {
                        country_code: deliveryStartPoint.CountryCode,
                        city: deliveryStartPoint.City,
                        address: typeof deliveryStartPoint.Address === "string"
                            ? deliveryStartPoint.Address
                            : JSON.stringify(deliveryStartPoint.Address),
                    },
                    to: userLocation
                        ? { latitude: userLocation.lat, longitude: userLocation.lng }
                        : undefined,
                    goods: [
                        {
                            width: dimensions.Width,
                            height: dimensions.Height,
                            length: dimensions.Length,
                            weight: dimensions.Weight,
                        },
                    ],
                    servicePath: '/Cdek/ProcessRequest',
                    root: "cdek-map",
                    apiKey: "49ea5682-b2de-4233-86e3-e009cff7dfb4",
                    debug: true,
                    defaultLocation: userLocation
                        ? [userLocation.lng, userLocation.lat]
                        : "Россия",
                    lang: "rus",
                    currency: "RUB",
                    onReady: () => {
                        console.log("Виджет готов");
                        addCloseButton(); // Добавляем кнопку закрытия сразу после готовности виджета
                    },
                    onChoose: (type, tariff, address) => {
                        console.log('Выбран метод доставки', type, tariff, address);
                        setIsDeliveryChosen(true);

                        setDeliveryDetails(prev => ({
                            ...prev,
                            Tariff: tariff["tariff_name"],
                            Price: tariff["delivery_sum"],
                            Duration: tariff["period_max"],
                            Type: type,
                            ShipmentCode: address["code"],
                            TariffCode: tariff["tariff_code"]
                        }));

                        setDeliveryEndPoint(prev => ({
                            ...prev,
                            Address: address["address"],
                            City: address["city"],
                            CountryCode: address["country_code"]
                        }));

                        if (props.tradeStateId === TradeStates.Traded && props.priceHistoryLength > 0) {
                            getTradeWinnerId();
                        }
                    },
                    onCalculate: (tariffs, address) => {
                        console.log('Подсчитаны тарифы', tariffs);
                        console.log('Адрес подсчета', address);
                    },
                    onError: (error) => {
                        console.error('Ошибка в CDEK Widget:', error);
                    },
                });
            }
        };

        if (dimensions && deliveryStartPoint && isWidgetVisible) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    initializeCDEKWidget(userLocation);
                },
                () => {
                    initializeCDEKWidget(null);
                }
            );
        }
    }, [dimensions, deliveryStartPoint, isWidgetVisible]);

    /**
     * @function addCloseButton - функция для добавления кнопки закрытия виджета.
     */
    const addCloseButton = () => {
        const cdekMap = document.getElementById("cdek-map");
        if (cdekMap) {
            const oldCloseButton = document.getElementById("cdek-close");
            if (oldCloseButton) oldCloseButton.remove();

            // Кнопка закрытия
            const closeButton = document.createElement("button");
            closeButton.className = "close-button";
            closeButton.id = "cdek-close";
            closeButton.innerText = "X";
            closeButton.onclick = () => setIsWidgetVisible(false); // Скрываем виджет

            cdekMap.appendChild(closeButton);
        }
    };

    /**
     * @effect - эффект для добавления кнопки "Сохранить данные" и надписи "Сохранено" после выбора способа доставки.
     */
    useEffect(() => {
        if (isDeliveryChosen && isWidgetVisible) {
            const addSaveButton = () => {
                const cdekMap = document.getElementById("cdek-map");
                if (cdekMap) {
                    const oldButton = document.getElementById("cdek-save");
                    const oldSavedText = document.getElementById("cdek-saved-text");

                    if (oldButton) oldButton.remove();
                    if (oldSavedText) oldSavedText.remove();

                    // Кнопка "Сохранить данные"
                    const saveButton = document.createElement("button");
                    saveButton.className = "save-button";
                    saveButton.id = "cdek-save";
                    saveButton.innerText = "Сохранить данные";
                    saveButton.onclick = () => setReadyToSave(true);

                    cdekMap.appendChild(saveButton);

                    if (isSaved) {
                        const savedText = document.createElement("div");
                        savedText.id = "cdek-saved-text";
                        savedText.innerText = "Сохранено";
                        cdekMap.appendChild(savedText);
                    }
                }
            };

            addSaveButton();
        }
    }, [isDeliveryChosen, isSaved, isWidgetVisible]);

    /**
     * @effect - эффект для сохранения данных доставки при готовности к сохранению.
     */
    useEffect(() => {
        if (readyToSave && deliveryDetails && deliveryEndPoint) {
            savePayerChoiceDelivery();
            setReadyToSave(false);
        }
    }, [readyToSave, deliveryDetails, deliveryEndPoint]);

    /**
     * @function getTradeWinnerId - функция для получения идентификатора победителя торгов.
     */
    const getTradeWinnerId = () => {
        Fetcher(`/Bidding/GetTradeWinnerId?lotId=${props.lotId}`, {
            method: "GET"
        })
            .then(res => res.json())
            .then(result => {
                console.log(result);
                if (result) {
                    setDeliveryDetails(prev => ({
                        ...prev,
                        WinnerId: result
                    }));
                }
            });
    };

    /**
     * @function savePayerChoiceDelivery - функция для сохранения выбранных данных доставки.
     */
    const savePayerChoiceDelivery = () => {
        if (!deliveryDetails || !dimensions || !deliveryStartPoint || !deliveryEndPoint) {
            console.error("Не все данные доставки доступны");
            return;
        }

        let body = {
            LotId: deliveryDetails.LotId,
            Width: dimensions.Width,
            Height: dimensions.Height,
            Weight: dimensions.Weight,
            Length: dimensions.Length,
            DeliveryStartPointAddressId: deliveryDetails.FromAddressId,
            ToAddress: deliveryEndPoint.Address,
            ToCity: deliveryEndPoint.City,
            ToCountryCode: deliveryEndPoint.CountryCode,
            Tariff: deliveryDetails.Tariff,
            Price: deliveryDetails.Price.toString(),
            WinnerId: deliveryDetails.WinnerId,
            Duration: deliveryDetails.Duration,
            Type: deliveryDetails.Type,
            TariffCode: deliveryDetails.TariffCode,
            ShipmentCode: deliveryDetails.ShipmentCode,
            SendingStatus: SendingStatuses.WaitSending
        };

        Fetcher("/CdekApi/InsertDeliveryDetails", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(result => {
                console.log(result);
                setIsSaved(true);
            });
    };

    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {isWidgetVisible && <div id="cdek-map"></div>}
        </div>
    );
}

export default CDEKWidget;