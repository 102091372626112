import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'
import { Tree } from 'primereact/tree';
import Fetcher from "../Common/Fetch";
import 'rc-slider/assets/index.css';
import classNames from 'classnames';

interface CategoryShortInfo {
    key: number,
    label: string,
    data: any,
    icon: string,
    children: []
}

interface IPropsType {
    CategoryId: number
}

function TreeCategories(props: IPropsType) {

    // состояние для открытия/скрытия фильтра
    const [isOpen, setIsOpen] = useState(false);

    const signalSelectCategory = useSignal();
    // данное свойство заполнятеся запросом с сервера и получает в себя дерево категорий
    const [subCategories, setSubCategories] = useState<CategoryShortInfo[]>([]);

    // данное свойство относится к дереву категорий и храит текущий выделенный элемент
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [activeNodeKey, setActiveNodeKey] = useState(props.CategoryId);

    // некоторый флаг необходимоый нам для единоразового вызова метода, получающего информацию о эцп пользователя
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    // маленький hook метод который отработает у нас всего доин раз для получения эцп пользователя
    useEffect(() => {
        if (isFirstLoad) {
            Fetcher("/Category/GetListCategories", {}).then(value => value.json())
                .then((result) => {
                    console.log('выводим дерево категорий:');
                    console.log(result);
                    var root = {
                        key: 0,
                        label: '',
                        children: result
                    }
                    var parentCategory = searchTree(root, props.CategoryId, null);
                    console.log('parentCategory=', parentCategory);

                    if (parentCategory != null) {
                        expandedKeys[parentCategory.key] = true;
                    }

                    console.log('expandedKeys:', expandedKeys);

                    setSubCategories(result);
                }
           );
        }
    }, [isFirstLoad]);

    const searchTree = (element, matchingTitle, parent) => {
        if (element.key == matchingTitle) {
            return parent;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = searchTree(element.children[i], matchingTitle, element);
            }
            return result;
        }
        return null;
    }

    // данный метод вызывается при выборе некоторой категории из дерева категорий
    const handleNodeClick = (key) => {
        console.log('handleNodeClick', key);
        if (key == null || key == undefined)
            signalSelectCategory('categoryChange', props.CategoryId);
        else
            signalSelectCategory('categoryChange', key);
        setActiveNodeKey(key);
        onToggle(key);
    };

    const onToggle = (nodeKey) => {
        const _expandedKeys = { ...expandedKeys };
        if (_expandedKeys[nodeKey]) {
            delete _expandedKeys[nodeKey];
        } else {
            _expandedKeys[nodeKey] = true;
        }
        setExpandedKeys(_expandedKeys);
    };

    return (
        <React.Fragment>
            <h5 className="sidebar-title" onClick={() => { setIsOpen(!isOpen) }}>Подкатегории <img src="/main-images/categoryFilterArrow.svg"></img></h5>
            <div className="subCategoriesTree" style={{ display: isOpen ? 'flex' : 'none' }}>
                <Tree
                    value={subCategories}
                    selectionMode="single"
                    expandedKeys={expandedKeys}
                    onToggle={(e) => setExpandedKeys(e.value)}
                    className="w-full md:w-30rem p-tree p-component p-tree-selectable"
                    nodeTemplate={(node, options) => (
                        <div className={`node-wrapper ${activeNodeKey === node.key ? 'active' : ''}`}>
                            {(node.children && node.children.length > 0) ? (
                                <>
                                    <button
                                        className={classNames('toggler-button', {
                                            'rotated': expandedKeys[node.key]
                                        })}
                                        onClick={() => handleNodeClick(node.key)}
                                    >

                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                            <path d="M4.80794 0.230466L1.09346 4.68785C0.659238 5.20891 1.02976 6 1.70803 6L8.29197 6C8.97024 6 9.34076 5.20892 8.90654 4.68785L5.19206 0.230466C5.09211 0.110528 4.90789 0.110528 4.80794 0.230466Z" fill="#690097" />
                                        </svg>
                                    </button>

                                    <span
                                        className="node-label"
                                        onClick={() => handleNodeClick(node.key)}
                                    >
                                        {node.label}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <button
                                        className={classNames('toggler-button circleList', {
                                            'rotated': expandedKeys[node.key]
                                        })}
                                        onClick={() => handleNodeClick(node.key)}
                                    >

                                    </button>

                                    <span
                                        className="node-label"
                                        onClick={() => handleNodeClick(node.key)}
                                    >
                                        {node.label}
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
    );
}

export default TreeCategories;