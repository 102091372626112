/* eslint-disable prettier/prettier */
import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";
import { ISelectListItem } from "../types";

interface IPropsType {
    defaultLabel: string,
    optionsList: Array<Object>,
    onChange: Function
}

function CustomSelect(props: IPropsType) {
    const [activeOption, setActiveOption] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className={isOpen ? "customSelect2 open" : "customSelect2"}>
            <div className="activeOption" onClick={() => {setIsOpen(!isOpen)}}>
                {activeOption ? activeOption : props.defaultLabel}
                <svg style={{transform: isOpen ? 'rotate(180deg)' : ''}} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path d="M5.19205 5.76856L8.90654 1.31117C9.34076 0.790108 8.97024 -0.000977755 8.29197 -0.000977755L1.70803 -0.000977755C1.02976 -0.000977755 0.659236 0.790107 1.09345 1.31117L4.80794 5.76856C4.90789 5.8885 5.09211 5.8885 5.19205 5.76856Z" fill="#170021" />
                </svg>
            </div>
            <div className="options">
                {props.optionsList.map((option) =>
                    <p className={option.Value} onClick={() => {
                        setActiveOption(option.Text)
                        props.onChange(option.Value)
                        setIsOpen(false)
                    }}>{option.Text}</p>
                )}
            </div>
        </div>
    );
}

export default CustomSelect;