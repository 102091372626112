import React, { useState, useEffect, useRef } from "react";
import Fetcher from "../../Common/Fetch";
import { IDeliveryData } from "../../Common/types";
import select from "../../Common/Form/Select";

/**
 * @interface City - интерфейс, описывающий структуру данных города.
 * @property city_uuid - уникальный идентификатор города.
 * @property code - код города.
 * @property full_name - полное название города.
 */
interface City {
    city_uuid: string;
    code: number;
    full_name: string;
}

/**
 * @interface Office - интерфейс, описывающий структуру данных офиса.
 * @property uuid - уникальный идентификатор офиса.
 * @property name - название офиса.
 * @property location - объект, содержащий информацию о местоположении офиса.
 * @property location.address - адрес офиса.
 * @property location.address_full - полный адрес офиса.
 * @property location.country_code - код страны офиса.
 */
interface Office {
    uuid: string;
    name: string;
    location: {
        address: string;
        address_full: string;
        country_code: string;
    };
}

/**
 * @function DeliveryData - функциональный компонент, отвечающий за выбор города и офиса для доставки.
 * Компонент позволяет пользователю искать город, выбирать офис и сохранять точку доставки. Далее эта информация будет сохраняться в 
 * данные доставки лота (таблица LotDeliveryDetails)
 */
function DeliveryData() {
    const [searchQueryCity, setSearchQueryCity] = useState(""); // Состояние для поискового запроса города
    const [searchQueryOffice, setSearchQueryOffice] = useState(""); // Состояние для поискового запроса офиса
    const [cities, setCities] = useState<City[]>([]); // Список городов
    const [city, setCity] = useState<City | null>(null); // Выбранный город
    const [showCitySuggestions, setShowCitySuggestions] = useState(false); // Флаг отображения списка городов
    const [isLoadingCities, setIsLoadingCities] = useState(false); // Флаг загрузки городов
    const [errorCities, setErrorCities] = useState<string | null>(null); // Ошибка при загрузке городов

    const [offices, setOffices] = useState<Office[]>([]); // Список офисов
    const [showOfficeSuggestions, setShowOfficeSuggestions] = useState(false); // Флаг отображения списка офисов
    const [isLoadingOffices, setIsLoadingOffices] = useState(false); // Флаг загрузки офисов
    const [errorOffices, setErrorOffices] = useState<string | null>(null); // Ошибка при загрузке офисов
    const [office, setOffice] = useState<Office | null>(null); // Выбранный офис
    const [shipmentPoint, setShipmentPoint] = useState(""); // Точка отправления

    const officeInputRef = useRef<HTMLInputElement>(null); // Ref для input офисов

    /**
     * @function getCities - асинхронная функция для получения списка городов от СДЭК. При выборе позовляет выбирать ПВЗ в данном городе.
     * @param query - поисковый запрос для поиска городов.
     */
    const getCities = async (query: string) => {
        if (query.length < 3) {
            setCities([]); // Очищаем список, если запрос слишком короткий
            return;
        }

        setIsLoadingCities(true);
        setErrorCities(null);

        try {
            const response = await Fetcher(`/Cdek/ProcessRequest?action=cities&name=${encodeURIComponent(query)}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Список городов СДЭК:", data);
                setCities(data);
            } else {
                console.error("Ошибка при получении списка городов:", response.statusText);
                setErrorCities("Не удалось загрузить список городов");
            }
        } catch (error) {
            console.error("Ошибка при запросе списка городов:", error);
            setErrorCities("Произошла ошибка при загрузке данных");
        } finally {
            setIsLoadingCities(false);
        }
    };

    /**
     * @function getDeliveryStartPoint - асинхронная функция для получения начальной точки доставки, во время загрузки страницы, где используется компонент
     */
    const getDeliveryStartPoint = async () => {
        try {
            const response = await Fetcher(`/CdekApi/GetDeliveryStartPoint`, {
                method: "GET"
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Получение начальной точки доставки", data);
                if (data.UpdatedItem) {
                    setSearchQueryCity(data.UpdatedItem.City);
                    setSearchQueryOffice(data.UpdatedItem.Address);
                }
            } else {
                console.error("Ошибка при получении начальной точки доставки", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка при запросе начальной точки доставки:", error);
        }
    }

    // Загрузка начальной точки доставки при монтировании компонента
    useEffect(() => {
        getDeliveryStartPoint();
    }, []);

    /**
     * @function getOffices - асинхронная функция для получения списка офисов в выбранном городе.
     */
    const getOffices = async () => {
        if (!city) {
            console.error("Город не выбран");
            return;
        }

        setIsLoadingOffices(true);
        setErrorOffices(null);

        try {
            const response = await Fetcher(`/Cdek/ProcessRequest?action=offices&city_code=${city.code}`, {
                method: "GET"
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Получение офисов", data);
                setOffices(data);
                setShowOfficeSuggestions(true); // Показываем список офисов
            } else {
                console.error("Ошибка при получении списка офисов:", response.statusText);
                setErrorOffices("Не удалось загрузить список офисов");
            }
        } catch (error) {
            console.error("Ошибка при запросе списка офисов:", error);
            setErrorOffices("Произошла ошибка при загрузке данных");
        } finally {
            setIsLoadingOffices(false);
        }
    };

    // Отложенный вызов API для городов, чтобы запросы не шли слишком часто
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQueryCity) {
                getCities(searchQueryCity);
                setShowCitySuggestions(true);
            } else {
                setCities([]);
                setShowCitySuggestions(false);
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQueryCity]);

    /**
     * @function handleCitySelect - функция для выбора города из списка.
     * @param selectedCity - выбранный город.
     */
    const handleCitySelect = (selectedCity: City) => {
        setSearchQueryCity(selectedCity.full_name);
        setCity(selectedCity);
        setShowCitySuggestions(false);
        setOffice(null); // Сбрасываем выбранный офис при смене города
    };

    /**
     * @function handleOfficeSelect - функция для выбора офиса из списка.
     * @param selectedOffice - выбранный офис.
     */
    const handleOfficeSelect = (selectedOffice: Office) => {
        setSearchQueryOffice(selectedOffice.name);
        setOffice(selectedOffice); 
        setShowOfficeSuggestions(false); 
        console.log(selectedOffice.name); // "RND34, Ростов-на-Дону, пр-т Стачки". Нужна первая часть
        const firstPart = selectedOffice.name.split(',')[0].trim();
        console.log(firstPart);
        setShipmentPoint(firstPart); // Устанавливаем точку отправления
    };

    // Закрытие списка офисов при клике вне input
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (officeInputRef.current && !officeInputRef.current.contains(event.target as Node)) {
                setTimeout(() => {
                    setShowOfficeSuggestions(false);
                }, 100); // Задержка в 100 мс
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    /**
     * @function saveDeliveryStartPoint - функция для сохранения точки доставки. Записывается в таблицу DeliveryLotDetails
     */
    const saveDeliveryStartPoint = () => {
        console.log("saveDeliveryStartPoint deliveryPoint", shipmentPoint);
        const deliveryStartPointModel = {
            Address: office.location.address,
            City: city.full_name,
            CountryCode: office.location.country_code,
            Code: city.code.toString(),
            ShipmentPoint: shipmentPoint // Точка отправления
        };

        console.log("body", deliveryStartPointModel);
        console.log("Serialized body:", JSON.stringify(deliveryStartPointModel));

        Fetcher("/CdekApi/SaveDeliveryStartPoint", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(deliveryStartPointModel)
        })
            .then(res => res.json())
            .then(result => {
                console.log("Результат сохранения стартовой точки доставки", result);
            });
    };

    return (
        <>
            <div className="row mt-5">
                <div className="col-12">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Поиск города</h6>
                        <input
                            className="defaultInput"
                            placeholder="Введите название города"
                            value={searchQueryCity}
                            onChange={(e) => setSearchQueryCity(e.target.value)}
                        />
                        {isLoadingCities && <div className="mt-2">Загрузка городов...</div>}
                        {errorCities && <div className="text-danger mt-2">{errorCities}</div>}
                    </div>

                    {/* Выпадающий список с предложениями городов */}
                    {showCitySuggestions && (
                        <div className="suggestions-list mt-2">
                            {cities.length > 0 ? (
                                <ul className="list-group">
                                    {cities.map((city) => (
                                        <li
                                            key={city.city_uuid}
                                            className="list-group-item list-group-item-action"
                                            onClick={() => handleCitySelect(city)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {city.full_name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-muted">Ничего не найдено</div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Второй input для офисов */}
            <div className="row mt-3">
                <div className="col-12">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Выбор офиса</h6>
                        <input
                            ref={officeInputRef}
                            className="defaultInput"
                            placeholder="Выберите офис"
                            value={office ? office.location.address : searchQueryOffice}
                            onFocus={() => {
                                if (city) {
                                    getOffices();
                                }
                            }}
                            onChange={(e) => setSearchQueryOffice(e.target.value)}
                        />
                        {isLoadingOffices && <div className="mt-2">Загрузка офисов...</div>}
                        {errorOffices && <div className="text-danger mt-2">{errorOffices}</div>}
                    </div>

                    {/* Выпадающий список с предложениями офисов */}
                    {showOfficeSuggestions && (
                        <div className="suggestions-list mt-2">
                            {offices.length > 0 ? (
                                <ul className="list-group">
                                    {offices
                                        .filter(office =>
                                            office.name.toLowerCase().includes(searchQueryOffice.toLowerCase()) ||
                                            office.location.address.toLowerCase().includes(searchQueryOffice.toLowerCase())
                                        )
                                        .map((office) => (
                                            <li
                                                key={office.uuid}
                                                className="list-group-item list-group-item-action"
                                                onClick={() => handleOfficeSelect(office)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {office.name} - {office.location.address}
                                            </li>
                                        ))
                                    }
                                </ul>
                            ) : (
                                <div className="text-muted">Офисы не найдены</div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-4">
                <button
                    className="defaultPinkButton"
                    type="submit"
                    onClick={saveDeliveryStartPoint}
                    disabled={!city || !office} // Кнопка неактивна, если город или офис не выбраны
                >
                    Сохранить
                </button>
            </div>
        </>
    );
}

export default DeliveryData;