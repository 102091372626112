import { Nullable, ISelectListItem, IErrorDictionary } from "../Common/types";

export enum MyActivityTypes {
    MyLotsActive = 1,
    MyLotsFuture = 2,
    MyLotsNonActive = 3,

    ForeignLotsActive = 4,
    ForeignLotsNonActive = 5,

    MySalesActive = 6,
    MySalesNonactive = 7,
    MyOrderBuyNowHistory = 8,

    FavouritesActive = 9,
    FavouritesNonActive = 10,
    Subscriptions = 11,
    MyApplications = 12,
    ApplicationAcceptance = 15
}

export enum SendingStatuses {
    WaitSending = 1,
    Sent,
    Delivered,
    All
}

export enum TradeStates {
    None = 0,
    New = 1,
    Approved = 32,
    Declined = 33,
    AllowAcceptance = 34,
    WaitingForTrade = 35,
    Trading = 36,
    Traded = 37,
    Completed = 38,
    StopedByOrganizer = 39,
    WaitingForConfirmation = 40,
    ApplicationProcessing = 41,
    TradeAbandoned = 42,
    LotTradeStatus = 43,
    DeclinedByOrganizer = 44
}

export interface IMyActivityFiltersModel {
    ParticipantId: Nullable<number>,
    OrganizerId: Nullable<number>,
    ParticipantName: string,
    OrganizerName: string,
    LotId: Nullable<number>,
    LotName: string,
    SearchType: MyActivityTypes,
    CurrentPage: number,
    OrderBy: string,
    Ascending: boolean,
    PageSize: number
}

export interface IMyActivityModel {
    Filters: IMyActivityFiltersModel,
    SelectedIds: Array<number>,
    Items: any
    TotalCount: Nullable<number>,
    GlobalLoading: boolean,
    FilterLoading: boolean,
    Title: string,
    SubTitle: string,
    MobileSideBarOpen: boolean,
    MobileFiltersOpen: boolean,
    ShowReviewModal: boolean,
    ReviewLot: IReviewLotViewModel
}

export interface IReviewLotViewModel {
    ReviewLotId: number,
    CompanyName: string,
    LotName: string
}