import React, { useState, useEffect } from "react";
import { ILotHeadInfo } from "./LotPage";
import PopupBuyNow from "./PopupBuyNow";
import { ILotModel, TradeStates, PaymentLotStatus } from "../../Common/types";
import DepositLot from "./DepositLot";
import { ApplicationDetails } from "../../TradeApplication/components/ApplicationDetails";
import CDEKWidget from "../../Shared/CDEKwidget";
import Fetcher from "../../Common/Fetch";
import AccountInfo from "../../Profile/AccountInfo";
import { current } from "@reduxjs/toolkit";
import { SendingStatuses } from "../../MyActivity/types";

interface IPropsType {
    lotHeadInfo: ILotHeadInfo,
    lotId: number,
    lotDetails: ILotModel,
    theme: string
}

function LotActionMenu(props: IPropsType) {
    const [isOpen, setIsOpen] = useState(false);
    const [lotDetails, setLotDetails] = useState<ILotModel>(props.lotDetails);
    const [isTradeEnd, setIsTradeEnd] = useState<boolean | null>(null);
    const [formattedDate, setFormattedDate] = useState<string>('');
    const [isTestingWidget, setIsTestingWidget] = useState(false); // Состояние для тестирования виджета
    const [lotWinnerIsCurrentCompany, setLotWinnerIsUserCompany] = useState(false);
    const [sendingStatusId, setSendingStatusId] = useState(0);

    useEffect(() => {
        const date = new Date(props.lotHeadInfo.LotTradePeriodEnd);
        const time = date.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });
        const dateString = date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'long',
            weekday: 'short'
        });
        const formatted = `${time} (${dateString})`;
        setFormattedDate(formatted);

        const dateNow = new Date();
        if (date < dateNow) {
            setIsTradeEnd(true);
        } else {
            setIsTradeEnd(false);
        }
    }, [props.lotHeadInfo]);

    const closeDepositPopup = () => {
        setIsOpen(false);
    };

    const getSendingStatus = async () => {
        try {
            const response = await Fetcher(`/CdekApi/GetSendingStatusByLotId?lotId=${props.lotId}`, {
                method: "GET"
            });

            if (response.ok) {
                const data = await response.json();

                if (data && data.UpdatedItem !== undefined) {
                    console.log("Статус доставки", data.UpdatedItem);
                    setSendingStatusId(data.UpdatedItem);
                } else {
                    console.error("Ошибка: данные о статусе доставки отсутствуют или имеют неверный формат.");
                }
            } else {
                const errorText = await response.text();
                console.error("Ошибка при запросе:", response.status, response.statusText, errorText);
            }
        } catch (error) {
            console.error("Произошла ошибка при выполнении запроса:", error);
        }
    };

    useEffect(() => {
        getSendingStatus();
    }, [props.lotId]);

    const compareUserWithLotWinner = () => {
        Fetcher(`/Bidding/CompareUserWithLotWinner?lotId=${props.lotId}`, {
            method: "GET"
        })
            .then(res => res.json())
            .then(result => {
                console.log("Результат получения победителя торга", result);
                if (result) {
                    setLotWinnerIsUserCompany(result);
                }
            })
    }

    const haveWinner = () => {
        const haveWinner = lotDetails && lotDetails.TradeStateId == TradeStates.Traded && lotDetails.LotPriceHistoryModel.length > 0;
        console.log("haveWinner", haveWinner);
        return haveWinner;
    }

    const isCdekWidgetVisible = () => {
        const isCdekWidgetVisible = haveWinner() && lotWinnerIsCurrentCompany && (sendingStatusId == SendingStatuses.WaitSending || sendingStatusId === null);
        console.log("isCdekWidgetVisible", isCdekWidgetVisible);
        return isCdekWidgetVisible;
    }

    useEffect(() => {
        console.log("Сравнение id текущей компании с компанией победителем", lotWinnerIsCurrentCompany);
    }, [lotWinnerIsCurrentCompany]);

    useEffect(() => {
        if (haveWinner()) {
            compareUserWithLotWinner();
        }
    }, [props.lotId, lotDetails]);

    return (
        <div className="lotActionMenu">
            <div className="title">
                <h4>{props.lotHeadInfo.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽</h4>
                <span>Текущая цена</span>
            </div>

            {!lotDetails.IsUserRoleAdmin &&
                lotDetails.LotTradeStateId === TradeStates.Trading &&
                !lotDetails.listPayments.some((obj) => obj.StatusId === PaymentLotStatus.PaidComission) &&
                lotDetails.CanMakeBet ? (
                lotDetails.DepositIsRequired && !lotDetails.IsDepositWrittenOff ? (
                    <button className="defaultPinkButton" onClick={() => setIsOpen(true)}>Внести задаток</button>
                ) : (
                    <button className="defaultPinkButton" onClick={() => setIsOpen(true)}>Сделать ставку</button>
                )
            ) : lotDetails.IsUserRoleAdmin ? (
                <p className="errorText">Администраторам нельзя делать ставки</p>
            ) : lotDetails.listPayments.some(
                (obj) =>
                    obj.StatusId === PaymentLotStatus.PaidComission ||
                    obj.StatusId === PaymentLotStatus.Paid
            ) ? (
                <p className="errorText">Забронирован</p>
            ) : null}

            {lotDetails.AllowApply && <ApplicationDetails lotId={lotDetails.Id} loadApp={true}></ApplicationDetails>}

            <div className="shortInfo">
                <div className="infoItem">
                    <span>Статус:</span>
                    <h5>{props.lotHeadInfo.LotState}</h5>
                </div>

                <div className="infoItem">
                    <span>Сделано ставок:</span>
                    <h5>{props.lotHeadInfo.CountBets}</h5>
                </div>

                <div className="infoItem">
                    <span>Вид торгов:</span>
                    <h5>{props.lotHeadInfo.TradeType}</h5>
                </div>

                <div className="infoItem">
                    <span>Размер задатка:</span>
                    <h5>{props.lotHeadInfo.StepValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽</h5>
                </div>

                <div className="infoItem">
                    <span>До окончания торгов:</span>
                    <h5>{formattedDate}</h5>
                </div>
            </div>

            {!props.lotDetails.IsOrganizer && props.lotDetails.BuyNowPrice && props.lotDetails.TradeStateId != TradeStates.Traded &&
                <PopupBuyNow lotId={props.lotId} isTradeEnd={false} shippingMethodId={lotDetails.ShippingMethodId} BuyNowPrice={props.lotDetails.BuyNowPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} mithraTheme={props.theme} />
            }

            {isOpen &&
                <DepositLot isOpen={isOpen} close={closeDepositPopup} theme={props.theme} lotDetails={props.lotDetails} lotId={props.lotId} />
            }

            {/* Временная кнопка для тестирования виджета */}
            {isCdekWidgetVisible() && <button className="defaultPinkButton" onClick={() => setIsTestingWidget(true)}>
                Рассчитать стоимость СДЭК
            </button>}

            {/* Виджет СДЭК для тестирования */}
            {isTestingWidget && (
                <CDEKWidget lotId={props.lotId} tradeStateId={lotDetails.TradeStateId} priceHistoryLength={lotDetails.LotPriceHistoryModel.length} />
            )}
        </div>
    );
}

export default LotActionMenu;