import React, { useState, useEffect } from "react";
import { ILotModel, TradeStates } from "../../Common/types";
import Fetcher from "../../Common/Fetch"



interface IPropsType {
    lotDetails: ILotModel,
    lotId: number
}

function OrganizerActions(props: IPropsType) {

    const [isOpen, setIsOpen] = useState(false);


    const copyLot = () => {
        window.location.href = "/TradeCreation/AddTrade?Id=" + props.lotId;
    }

    const changeLot = () => {
        window.location.href = "/TradeCreation/AddTrade?Id=" + props.lotId + "&isEdit=true";
    }

    const removeLot = () => {
        Fetcher("/TradeLotApi/RemoveTradeLot?lotId=" + props.lotId,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        window.location.href = "/MyActivity/MyLots";
                    }
                    else {
                        console.log('Лот с id=' + props.lotId + " не был удалён")
                    }
                });
    }

    const finishTradeLot = () => {
        Fetcher("/TradeLotApi/FinishTradeLot?lotId=" + props.lotId,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        window.location.href = "/MyActivity/MyLots";
                    }
                    else {
                        console.log(result.Error)
                    }
                });
    }

    const finishLot = () => {
        Fetcher("/TradeLotActionsApi/FinishLot",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    LotId: props.lotId
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        window.location.reload();
                    }
                    else {
                        console.log(result.Error)
                    }
                });
    }

    return(
        <div className="organizerActions">
            <div className={isOpen ? "customSelect2 open" : "customSelect2"}>
                <div className="activeOption" onClick={() => { setIsOpen(!isOpen) }}>
                    Действия Администратора
                    <svg style={{ transform: isOpen ? 'rotate(180deg)' : '' }} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M5.19205 5.76856L8.90654 1.31117C9.34076 0.790108 8.97024 -0.000977755 8.29197 -0.000977755L1.70803 -0.000977755C1.02976 -0.000977755 0.659236 0.790107 1.09345 1.31117L4.80794 5.76856C4.90789 5.8885 5.09211 5.8885 5.19205 5.76856Z" fill="#170021" />
                    </svg>
                </div>
                <div className="options">
                    <p onClick={copyLot}>Создать похожий лот</p>
                    {props.lotDetails.LotTradeStateId == TradeStates.Trading &&
                        <p onClick={finishLot}>Завершить торги</p>    
                    }
                    <p onClick={removeLot}>Удалить лот</p>
                    {(props.lotDetails.LotTradeStateId == TradeStates.New ||
                        props.lotDetails.LotTradeStateId == TradeStates.Approved ||
                        props.lotDetails.LotTradeStateId == TradeStates.AllowAcceptance ||
                        props.lotDetails.LotTradeStateId == TradeStates.Trading) && 
                        <p onClick={changeLot}>Редактировать лот</p>
                    }
                </div>
            </div>
        </div>
    )
}


export default OrganizerActions;

