/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { InputNumber } from 'primereact/inputnumber'
import { IDeliveryData, IDeliveryPoint, IDimensions } from "../Common/types";
import { InputText } from "primereact/inputtext";

interface IPropsTypes {
    lotId: number,
    handleDimensionChange(dimensions: { width: number, height: number, length: number, weight: number }): void,
    getDimensions(lotid: number): IDeliveryData,
    handleDeliveryStartPointChange(startPoint: {countryCode: string, city: string, address: string}) : void
}

function deliveryLot(props: IPropsTypes) {
    const [width, setWidth] = useState(0.0);
    const [height, setHeight] = useState(0.0);
    const [length, setLength] = useState(0.0);
    const [weight, setWeight] = useState(0.0);
    const [fromCity, setFromCity] = useState('');
    const [fromAddress, setFromAddress] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [toCity, setToCity] = useState('');
    const [toAddress, setToAddress] = useState('');
    const [tariff, setTariff] = useState('');
    const [price, setPrice] = useState(0.0);
    const [winnerId, setWinnerId] = useState(0);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (props.lotId) {
            getDimensions(props.lotId);
        }
    }, [props.lotId])

    useEffect(() => {
        setErrors([]);
    }, [width, height, length, weight, fromCity, fromAddress]);


    const handleSubmit = async (e: React.MouseEventHandler<HTMLInputElement>) => {
        if (validate()) {
            if (props.lotId) {
                await props.handleDimensionChange({ width, height, length, weight });
                await props.handleDeliveryStartPointChange({ countryCode: countryCode, city: fromCity, address: fromAddress })
            } else {
                setErrors(prevState => [...errors, "У лота ещё не успел сформироваться id"]);
            }
        } else {
            setErrors(prevState => [...errors, "Не введены обязательные данные для доставки"]);
        }
    }

    const validate = () => {
        return fromAddress != '' && fromCity != '';
    }

    const getDimensions = async (lotId) => {
        try {
            const deliveryData = await props.getDimensions(lotId);

            console.log('Dimensions getting', deliveryData);

            setWidth(deliveryData.Dimensions.Width);
            setHeight(deliveryData.Dimensions.Height);
            setLength(deliveryData.Dimensions.Length);
            setWeight(deliveryData.Dimensions.Weight);
            setFromCity(deliveryData.DeliveryStartPoint.City);
            setFromAddress(deliveryData.DeliveryStartPoint.Address);
            setCountryCode(deliveryData.DeliveryStartPoint.CountryCode);
            setToAddress(deliveryData.DeliveryEndPoint.Address);
            setToCity(deliveryData.DeliveryEndPoint.City);
            setTariff(deliveryData.DeliveryDetails.Tariff);
            setPrice(deliveryData.DeliveryDetails.Price);
            setWinnerId(deliveryData.DeliveryDetails.WinnerId);
        } catch (error) {
            console.error('Error in getDimensions:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Ширина</h6>
                        <InputNumber id="width" value={width ? width : 0} onValueChange={(e) => setWidth(e.value)} minFractionDigits={2} maxFractionDigits={5} min={0.1} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Высота</h6>
                        <InputNumber id="height" value={height} onValueChange={(e) => setHeight(e.value)} minFractionDigits={2} maxFractionDigits={5} min={0.1} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Длина</h6>
                        <InputNumber id="length" value={length} onValueChange={(e) => setLength(e.value)} minFractionDigits={2} maxFractionDigits={5} min={0.1} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <h6 className="field-label-16-roboto pt-0">Вес</h6>
                        <InputNumber id="weight" value={weight} onValueChange={(e) => setWeight(e.value)} minFractionDigits={2} maxFractionDigits={5} min={0.1} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <input className="btn btn-primary btn-font-14 btn-lg px-2 mb-4" type="submit" value="Сохранить данные доставки" onClick={handleSubmit} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {errors && <p>{ errors }</p> }
                </div>
            </div>
        </React.Fragment>
    )
}

export default deliveryLot;




