import React, { createContext, useContext, useState, useEffect } from "react";
import { fetcherPOSTJSON } from "../Common/Fetch";
import { SendingStatuses } from "../MyActivity/types";

/**
 * @constant SEARCH_TYPE_TO_SENDING_STATUS - Словарь с соответствием id фильтрации со статусами доставки для дальнейшего маппинга
 */
const SEARCH_TYPE_TO_SENDING_STATUS = {
    17: SendingStatuses.All,
    18: SendingStatuses.WaitSending,
    20: SendingStatuses.Delivered,
};

const LotCountsContext = createContext(null);

/**
 * @constant categories - Константа с данными по категориям, для фильтрации во вкладках внутри Моя активность
 * @property title - Название категории
 * @property links - Внутренние вкладки во вкладке с общей темой (title)
 * @property name - Название внутренней вкладки
 * @property url - Адрес страницы, куда будет переходить, после нажатия на вкладку
 * @property searchTypeId - id фильтра категории
 * @property stateFilter - Список фильтров для отправки запроса и возврата нужных лотов
 * @property stateFilter: stateName - Название статуса лота в фильтрах
 * @property stateFilter: id - Id статуса лота
 */
const categories = [
    {
        title: "Мои лоты",
        links: [
            {
                name: "Активные", url: "/myactivity/MyLots-Active", searchTypeId: 1,
                stateFilter: [
                    { stateName: 'Идет торг', id: 36 }
                ]
            },
            {
                name: "Будущие", url: "/myactivity/MyLots-Future", searchTypeId: 2,
                stateFilter: [
                    { stateName: 'Обработка заявок', id: 41 },
                    { stateName: 'Прием заявок', id: 34 },
                    { stateName: 'Ожидание начала торгов', id: 35 }
                ]
            },
            {
                name: "Неактивные", url: "/myactivity/MyLots-NonActive", searchTypeId: 3,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 },
                    { stateName: 'Завершен', id: 38 },
                    { stateName: 'Торг не состоялся', id: 42 },
                    { stateName: 'Подтвержден', id: 32 },
                    { stateName: 'Новый', id: 1 },
                    { stateName: 'Ожидание подтверждения', id: 40 }
                ]
            }
            ,
            {
                name: "Рассмотрение заявок", url: "/myactivity/MyLots-Acceptance", searchTypeId: 15
            }
        ]
    },
    {
        title: "Лоты с моими ставками",
        links: [
            {
                name: "Активные", url: "/myactivity/MyBets-Active", searchTypeId: 4,
                stateFilter: [
                    { stateName: 'Идет торг', id: 36 }
                ]
            },
            {
                name: "Неактивные", url: "/myactivity/MyBets-NonActive", searchTypeId: 5, 
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 },
                    { stateName: 'Завершен', id: 38 },
                    { stateName: 'Торг не состоялся', id: 42 },
                    { stateName: 'Подтвержден', id: 32 }
                ]
            },
            { name: "Мои заявки", url: "/myactivity/MyBets-MyTickets", searchTypeId: 12 }
        ]
    },
    {
        title: "Мои продажи",
        links: [
            {
                name: "Активные", url: "/myactivity/MySales-Active", searchTypeId: 6,
                stateFilter: [
                    { stateName: 'Обработка заявок', id: 41 },
                    { stateName: 'Прием заявок', id: 34 },
                    { stateName: 'Идет торг', id: 46 }
                ]
            },
            {
                name: "Неактивные", url: "/myactivity/MySales-NonActive", searchTypeId: 7,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 },
                    { stateName: 'Завершен', id: 38 },
                    { stateName: 'Торг не состоялся', id: 42 },
                    { stateName: 'Подтвержден', id: 32 }
                ]
            },
            {
                name: "Все отправки", url: "/myactivity/MySales-AllDelivery", searchTypeId: 17,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 }
                ]
            },
            {
                name: "Ожидают отправления", url: "/myactivity/MySales-WaitSending", searchTypeId: 18,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 }
                ]
            },
            {
                name: "Отправлены", url: "/myactivity/MySales-Sent", searchTypeId: 19,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 }
                ]
            },
            {
                name: "Доставлены", url: "/myactivity/MySales-Delivered", searchTypeId: 20,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 }
                ]
            },
        ]
    },
    {
        title: "Избранное",
        links: [
            {
                name: "Активные", url: "/myactivity/Favorite-Active", searchTypeId: 9,
                stateFilter: [
                    { stateName: 'Обработка заявок', id: 41 },
                    { stateName: 'Прием заявок', id: 34 },
                    { stateName: 'Идет торг', id: 36 }
                ]
            },
            {
                name: "Неактивные", url: "/myactivity/Favorite-NonActive", searchTypeId: 10,
                stateFilter: [
                    { stateName: 'Торг Завершен', id: 37 },
                    { stateName: 'Завершен', id: 38 },
                    { stateName: 'Торг не состоялся', id: 42 },
                    { stateName: 'Подтвержден', id: 32 }
                ]
            },
            { name: "Продавцы", url: "/myactivity/Favorite-Subscriptions", searchTypeId: 11 }
        ]
    },
];

/**
 * @function LotCountsProvider - контекст (провайдер) для получения количества лотов для его отображения в HeadFilters
 * @param children - дочерний компонент контекста (провайдера). В него обёрнут компонент MyActivity2 в index.art.tsx. 
 * <LotCountsProvider><MyActivity2></MyActivity2></LotCountsProvider>
 * @returns
 */
export const LotCountsProvider = ({ children }) => {
    const [lotCounts, setLotCounts] = useState({}); // Состояние, следящее за количеством лотов
    const [isLoading, setIsLoading] = useState(true); // Состояние, следящее за загрузкой

    /**
     * @effect - получение количества лотов для отображения
     */
    useEffect(() => {
        if (!categories || categories.length === 0) {
            console.error("categories не определен или пуст");
            setIsLoading(false);
            return;
        }

        fetchLotCounts(categories);
    }, [categories]);

    /**
     * @function fetchLotCountForTab - функция, получающая через промисы данные для категорий
     * @param searchTypeId - id фильтра категории
     * @returns Промис с количеством лотов в категории с переданным searchTypeId
     */
    const fetchLotCountForTab = async (searchTypeId: number): Promise<number> => {
        const sendingStatus = SEARCH_TYPE_TO_SENDING_STATUS[searchTypeId];
        const filters: any = {
            SearchType: searchTypeId,
            CurrentPage: 1,
            PageSize: 3,
            LotName: '',
        };
        if (sendingStatus !== undefined) {
            filters.SendingStatus = sendingStatus;
        }
        return new Promise<number>((resolve, reject) => {
            if (!fetcherPOSTJSON) {
                reject(new Error("fetcherPOSTJSON не определен"));
                return;
            }
            fetcherPOSTJSON("/TradeEditApi/GetMyTradeLots", JSON.stringify(filters), function (result) {
                if (result.Success === true) {
                    console.log(`Данные для searchTypeId ${searchTypeId}:`, result);
                    resolve(result.UpdatedItem.totalCount);
                } else {
                    console.error('Ошибка при получении количества лотов:', result);
                    reject(0);
                }
            });
        });
    };

    /**
     * @function fetchLotCounts - функция для получения количества лотов
     * @param categories - категория из константы categories
     */
    const fetchLotCounts = async (categories) => {
        try {
            console.log("Начало загрузки данных");
            const counts = await Promise.all(
                categories
                    .map(category => category.links.map(link => fetchLotCountForTab(link.searchTypeId)))
                    .flat()
            );

            const lotCounts = {};
            let globalIndex = 0;
            categories.forEach((category) => {
                category.links.forEach((link) => {
                    const key = `${category.title.toLowerCase().replace(/ /g, '')}_${link.name.toLowerCase().replace(/ /g, '')}`;
                    lotCounts[key] = counts[globalIndex];
                    globalIndex++;
                });
            });

            setLotCounts(lotCounts);
        } catch (error) {
            console.error("Ошибка при загрузке количества лотов:", error);
        } finally {
            setIsLoading(false); 
        }
    };

    /**
     * @effect - логирование категорий и количества лотов в них
     */
    useEffect(() => {
        console.log('LotCountsProvider: categories', categories);
        console.log('LotCountsProvider: lotCounts', lotCounts);
    }, [categories, lotCounts]);

    /**
     * Получение количества лотов
     */
    useEffect(() => {
        fetchLotCounts(categories);
    }, [categories]);

    return (
        <LotCountsContext.Provider value={{ lotCounts, isLoading, categories }}>
            {children}
        </LotCountsContext.Provider>
    );
};

export const useLotCounts = () => useContext(LotCountsContext);